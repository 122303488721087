<template>
  <div v-if="loaded">
    <div id="material_editor" class="columns padding-x-2">
      <div class="column padding-x-4">
        <div class="section-title">
          Material
        </div>
        <div class="columns is-mobile">
          <div class="column">
            <b-field class="required" label="Name" :type="{'is-danger': errors.has('name')}" :message="errors.first('name')">
              <b-input v-model="grade.name" v-validate="'required'" name="name" data-vv-as="first name" maxlength="30" />
            </b-field>
          </div>
          <div class="column is-narrow">
            <div style="height: 30px" />
            <b-switch v-model="grade.public" true-value="1" false-value="0" style="margin-right: 80px">
              Public
            </b-switch>
            <b-switch v-model="grade.chrome" true-value="1" false-value="0">
              Chrome
            </b-switch>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column">
            <b-field class="required" label="Yield Strength Min" :type="{'is-danger': errors.has('yieldStrengthMin')}" :message="errors.first('yieldStrengthMin')">
              <b-input v-model="grade.yieldStrengthMin" v-validate="'required'" name="yieldStrengthMin" data-vv-as="Yield Strength Min" />
            </b-field>
          </div>
          <div class="column">
            <b-field class="required" label="Yield Strength Max" :type="{'is-danger': errors.has('yieldStrengthMax')}" :message="errors.first('yieldStrengthMax')">
              <b-input v-model="grade.yieldStrengthMax" v-validate="'required'" name="yieldStrengthMax" data-vv-as="Yield Strength Max" />
            </b-field>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column">
            <b-field class="required" label="Tensile Strength Min" :type="{'is-danger': errors.has('tensileStrengthMin')}" :message="errors.first('tensileStrengthMin')">
              <b-input v-model="grade.tensileStrengthMin" v-validate="'required'" name="tensileStrengthMin" data-vv-as="Tensile Strength Min" />
            </b-field>
          </div>
          <div class="column">
            <b-field class="required" label="Ksi <= 4.5" :type="{'is-danger': errors.has('gradeKsiLess')}" :message="errors.first('gradeKsiLess')">
              <b-select v-model="grade.gradeKsiLess" v-validate="'required'" expanded name="gradeKsiLess" data-vv-as="Ksi value">
                <option v-for="ksi in ksiValues" :key="ksi.id" :value="ksi.id">
                  {{ ksi.label }}
                </option>
              </b-select>
            </b-field>
            <b-switch v-model="grade.gradeKsiLessHasMst" true-value="1" false-value="0">
              MST
            </b-switch>
          </div>
          <div class="column">
            <b-field class="required" label="Ksi > 4.5" :type="{'is-danger': errors.has('gradeKsiGreater')}" :message="errors.first('gradeKsiGreater')">
              <b-select v-model="grade.gradeKsiGreater" v-validate="'required'" expanded name="gradeKsiGreater" data-vv-as="Ksi value">
                <option v-for="ksi in ksiValues" :key="ksi.id" :value="ksi.id">
                  {{ ksi.label }}
                </option>
              </b-select>
            </b-field>
            <b-switch v-model="grade.gradeKsiGreaterHasMst" true-value="1" false-value="0">
              MST
            </b-switch>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column">
            <b-field label="Compressive Strength">
              <div class="body">
                <b-field>
                  <b-input v-model="grade.compressiveStrength" expanded />
                  <p class="control">
                    <span class="button is-static">%</span>
                  </p>
                </b-field>
              </div>
            </b-field>
          </div>
          <div class="column">
            <b-field class="required" label="Guaranteed Wall" :type="{'is-danger': errors.has('guaranteedWall')}" :message="errors.first('guaranteedWall')">
              <div class="body">
                <b-field>
                  <b-input v-model="guaranteedWall" v-validate="'required'" name="guaranteedWall" data-vv-as="Guaranteed Wall" expanded />
                  <p class="control">
                    <span class="button is-static">%</span>
                  </p>
                </b-field>
              </div>
            </b-field>
          </div>
        </div>

        <hr>

        <div class="columns">
          <div class="column is-clickable" @click="stripingModal('TubeAndCoupling')">
            <label class="label is-medium">Premium Tube &amp; Coupling</label>
            <StripingDiagram
              :class="{selected: selectedType === 'TubeAndCoupling'}"
              class="padding-y-1"
              type="TubeAndCoupling"
              :grade-stripes="gradeStripes" />
          </div>
          <div class="column is-clickable" @click="stripingModal('SemiPremiumTubeAndCoupling')">
            <label class="label is-medium">Semi-Premium Tube &amp; Coupling</label>
            <StripingDiagram
              :class="{selected: selectedType === 'SemiPremiumTubeAndCoupling'}"
              class="padding-y-1"
              type="SemiPremiumTubeAndCoupling"
              :grade-stripes="gradeStripes" />
          </div>
          <div class="column is-clickable" @click="stripingModal('PlainEnd')">
            <label class="label is-medium">Plain End</label>
            <StripingDiagram
              :class="{selected: selectedType === 'PlainEnd'}"
              class="padding-y-1"
              type="PlainEnd"
              :grade-stripes="gradeStripes" />
          </div>
          <div class="column is-clickable" @click="stripingModal('SemiFlush')">
            <label class="label is-medium">Semi-Flush</label>
            <StripingDiagram
              :class="{selected: selectedType === 'SemiFlush'}"
              class="padding-y-1"
              type="SemiFlush"
              :grade-stripes="gradeStripes" />
          </div>
        </div>

        <!-- Testing: -->
        <!-- <img v-if="grade" style="width: 200px" :src="selectedTypeImageUrl" alt="">
        <img v-if="grade" style="width: 200px" :src="selectedTypeImageUrlSp" alt="">
        <img v-for="(image, index) of imageData" :key="index" style="width: 200px" :src="image.imageData" alt=""> -->
        <StripingEditor3D :grade-stripes="gradeStripes" :type="selectedType" :material="grade.name || ''" :special-coupling="specialCoupling" @change.native="changeGradeStripes" />
      </div>
    </div>
    <div class="padding-y-6" />
    <manage-buttons @cancel="cancel" @save="save" />
  </div>
</template>

<script>

import Vue2Filters from 'vue2-filters'
import AdminApi from '@/apis/AdminApi'
import ManageButtons from '@/components/navigation/ManageButtons'
import { SnackbarProgrammatic as Snackbar } from 'buefy'
// import StripingEditor from '@/components/modals/StripingEditor'
import StripingEditor3D from '@/components/modals/StripingEditor3D'
import StripingDiagram from '@/components/misc/StripingDiagram'

import { EventBus, GET_VIEWER_SCREENSHOT } from '@/webgl/EventBus'

export default {
  components: {
    ManageButtons,
    StripingDiagram,
    // StripingEditor,
    StripingEditor3D
  },
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      grade: {
        name: null,
        gradeKsiLess: null,
        gradeKsiLessHasMst: 0,
        gradeKsiGreater: null,
        gradeKsiGreaterHasMst: 0,
        yieldStrengthMin: null,
        yieldStrengthMax: null,
        tensileStrengthMin: null,
        guaranteedWall: 0.875,
        bendingModulus: 218.166,
        compressiveStrength: null,
        chrome: 0,
        public: 1
      },
      ksiValues: [
        { id: 55, label: '55' },
        { id: 75, label: '75' },
        { id: 80, label: '80' },
        { id: 85, label: '85' },
        { id: 90, label: '90' },
        { id: 95, label: '95' },
        { id: 100, label: '100' },
        { id: 105, label: '105' },
        { id: 110, label: '110' },
        { id: 125, label: '125' },
        { id: 140, label: '140' }
      ],
      loaded: false,
      guaranteedWall: 87.5,
      gradeStripes: {
        tncField: 'white',
        tncCouplingStripe1: 'none',
        tncCouplingStripe2: 'none',
        tncCouplingStripe3: 'none',
        tncCouplingStripe4: 'none',
        tncTubeStripe1: 'none',
        tncTubeStripe2: 'none',
        tncTubeStripe3: 'none',
        tncTubeStripe4: 'none',
        sptncField: 'white',
        sptncCouplingStripe1: 'none',
        sptncCouplingStripe2: 'none',
        sptncCouplingStripe3: 'none',
        sptncCouplingStripe4: 'none',
        sptncTubeStripe1: 'none',
        sptncTubeStripe2: 'none',
        sptncTubeStripe3: 'none',
        sptncTubeStripe4: 'none',
        plainendStripe1: 'none',
        plainendStripe2: 'none',
        plainendStripe3: 'none',
        plainendStripe4: 'none',
        semiflushStripe1: 'none',
        semiflushStripe2: 'none',
        semiflushStripe3: 'none',
        semiflushStripe4: 'none'
      },
      selectedType: 'TubeAndCoupling',
      images: {
        TubeAndCoupling: 'tubecouplingImg',
        TubeAndCouplingSp: 'tubecouplingImgSp',
        SemiPremiumTubeAndCoupling: 'semipremtubecouplingImg',
        SemiPremiumTubeAndCouplingSp: 'semipremtubecouplingImgSp',
        PlainEnd: 'plainendImg',
        PlainEndSp: 'plainendImgSp',
        SemiFlush: 'semiflushImg',
        SemiFlushSp: 'semiflushImgSp'
      },
      specialCoupling: false,

      imageData: {}
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    selectedTypeImageUrl () {
      if (this.grade && this.selectedType) {
        return this.grade[this.images[this.selectedType]] || this.grade.screenshotUrl
      } else {
        return ''
      }
    },
    selectedTypeImageUrlSp () {
      if (this.grade && this.selectedType) {
        return this.grade[this.images[this.selectedType + 'Sp']] || this.grade.screenshotUrl
      } else {
        return ''
      }
    }
  },
  watch: {
    gradeStripes: {
      handler (next) {
        // this.setCouplingImage()
      },
      deep: true
    }
  },
  async created () {
    if (this.id === 'new') {
      this.$store.commit('setTitle', 'New Material')
    } else {
      this.grade = await AdminApi.getGradeById(this.id)
      this.guaranteedWall = this.grade.guaranteedWall * 100
      this.$store.commit('setTitle', 'Material: ' + (this.grade.name))
    }

    // get saved grade stripes for diagram
    const stripes = await AdminApi.getGradeColorsById(this.grade.id)
    if (stripes) {
      this.gradeStripes = stripes
    }

    this.loaded = true
  },
  methods: {
    changeGradeStripes () {
      // Set image
      this.setCouplingImage()
      this.specialCoupling = true
      this.setCouplingImage(true)
    },
    setCouplingImage (specialCoupling = false) {
      this.$nextTick(() => {
        EventBus.$emit(GET_VIEWER_SCREENSHOT, (data) => {
          if (data.details) {
            this.imageData = {
              ...this.imageData,
              [data.details.screenshot_key + (specialCoupling ? 'Sp' : '')]: data
            }
            if (specialCoupling) {
              this.specialCoupling = false
            }
          }
        })
      })
    },
    async save (redirect = true) {
      if (await this.$validator.validateAll()) {
        const loadingComponent = this.$buefy.loading.open({ container: null })

        this.grade.guaranteedWall = this.guaranteedWall / 100
        let gradeId = this.id

        if (this.id === 'new') {
          this.grade = await AdminApi.createGrade(this.grade)
          gradeId = this.grade.id
        }

        this.grade = await AdminApi.updateGrade(gradeId, {
          ...this.grade,
          imageData: { ...this.imageData }
        })
        this.gradeStripes = await AdminApi.updateGradeColors(gradeId, this.gradeStripes)

        loadingComponent.close()

        this.$buefy.toast.open({
          message: this.grade.name + ' saved',
          type: 'is-success'
        })

        if (redirect) {
          this.$router.push({ name: 'manage_materials' })
        }
      }
    },
    async cancel () {
      this.$router.push({ name: 'manage_materials' })
    },
    async uploadFile (file) {
      try {
        const reader = new FileReader()

        if (file.size < 1000000) {
          reader.onload = () => {
            this.grade.imageType = file.type
            this.grade.imageData = reader.result
          }

          reader.readAsDataURL(file)
        } else {
          this.errors.items.push('imageUp')
          Snackbar.open({
            message: 'File size should be less than 1MB',
            type: 'is-warning',
            position: 'is-top'
          })
          await this.$validator.validateAll()
        }
      } catch (e) {
        console.log(e)
      }
    },
    async stripingModal (type) {
      this.selectedType = type
      // this.$buefy.modal.open({
      //   parent: this,
      //   component: StripingEditor,
      //   props: {
      //     gradeStripes: this.gradeStripes,
      //     type: type,
      //     material: this.grade.name
      //   },
      //   events: {
      //     change: newGradeStripes => {
      //       this.gradeStripes = newGradeStripes
      //     }
      //   }
      // })
    }
  }
}

</script>

<style lang="scss" scoped>
  /deep/ .pipe {
    opacity: 0.2;
    &:hover,
    .selected & {
      opacity: 1;
    }
  }
  .label.is-medium {
    font-size: 14px;
  }
</style>
